import { GlobalEventsService } from '../services/global-events.service';
import { createInitializerQueueList } from './initializer-manager';
import { SeoService } from '../services/seo.service';
import { APP_INITIALIZER, Injector, Provider } from '@angular/core';
import { DecoratorService } from '../helpers/decorator-service.service';
import { InstallAppService } from '../services/install-app.service';
import { GoogleTagManagerService } from '../services/google-tag-manager.service';
import { ServiceWorkerService } from '../services/service-worker.service';
import { ZendeskChatService } from '../services/zendesk/zendesk-chat.service';
import { ThemeService } from '../services/theme.service';
import { AnimatedFaviconService } from '../services/animated-favicon.service';

/**
 * The `initApp` function initializes the DecoratorService with the provided Injector.
 * @param {Injector} injector - The `injector` parameter in the `initApp` function is of type
 * `Injector`. It is used to provide dependencies to the application components. The `Injector` is a
 * dependency injection container that resolves dependencies for classes and services in Angular
 * applications.
 * @returns A function is being returned.
 */
export function initApp(injector: Injector) {
  return () => {
    DecoratorService.injector = injector;
  };
}

export const provideAppInitializer = () => {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [Injector],
      multi: true,
    },
    ...parallelAppInitializer,
  ];
};

const parallelAppInitializer: Provider[] = createInitializerQueueList([
  {
    token: ThemeService,
    initializerFn: (service: ThemeService) => service.resolveTheme$(),
  },
  {
    token: GlobalEventsService,
    initializerFn: (service: GlobalEventsService) => service.initGlobalEvent(),
  },
  {
    token: GoogleTagManagerService,
    initializerFn: (service: GoogleTagManagerService) => service.addGtmToDom(),
  },
  {
    token: ServiceWorkerService,
    initializerFn: (service: ServiceWorkerService) => service.registerServiceWorker(),
  },
  {
    token: SeoService,
    initializerFn: (service: SeoService) => service.updateGlobalHeadLink(),
  },
  {
    token: ZendeskChatService,
    initializerFn: (service: ZendeskChatService) => service.initChat(),
  },
  {
    token: InstallAppService,
    initializerFn: (service: InstallAppService) => service.resolveShowVariables(),
  },
  {
    token: AnimatedFaviconService,
    initializerFn: (service: AnimatedFaviconService) => service.init(),
  }
]);
