import { AppComponent } from './app.component';
import { provideRouter, Routes, withInMemoryScrolling } from '@angular/router';
import { APP_ID, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SsWebSocketsModule } from './core/vendor/ss-web-sockets/ss-web-sockets.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { GlobalErrorHandler } from './core/helpers/global-error-handler';
import { CacheControlInterceptor } from './core/interceptors/cache-control.interceptor';
import { HostRequestsInterceptor } from './core/interceptors/host-requests.interceptor';
import { provideAppInitializer } from './core/initializer/initializer-queue';
import { DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideABTest } from './core/ab-test/ab-test';
import { abTestList } from './core/ab-test/ab-test.data';
import { provideStoreInitializer } from 'ngx-unificator/store';
import { GlobalStore } from './core/store';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      { path: '', loadChildren: () => import('./page/page.module').then(m => m.PageModule) },
    ],
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideStoreInitializer(GlobalStore),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch(),
    ),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top'
      }),
    ),
    provideAppInitializer(),
    provideNgxMask(),
    provideABTest(abTestList),
    importProvidersFrom(
      BrowserModule.withServerTransition({ appId: 'ss' }),
      FormsModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      SsWebSocketsModule,
      NgxMaskDirective,
      HammerModule,
    ),
    DecimalPipe,
    provideNgxMask(),
    { provide: APP_ID, useValue: 'ss' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheControlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HostRequestsInterceptor,
      multi: true,
    },
  ],
};
