import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CookieService } from 'ngx-unificator/services';
import { map, tap } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import { EnvironmentService } from './environment.service';
import { DOCUMENT } from '@angular/common';
import { ArabicService } from './arabic.service';

/**
 * Name of cookie where current theme value is stored
 * use 'theme-dark' name instead 'theme' to support users previous cookie value (when we had only 2 themes)
 */
export const THEME_COOKIE = 'theme';

/**
 * Available themes
 */
export enum THEME {
  DEFAULT = 0,
  AR = 1,
}

/**
 * Global themes classes strings
 */
export const GLOBAL_CLASSES = {
  DEFAULT: 'default',
  AR: 'ar',
};

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  /**
   * BehaviorSubject which hold current theme value
   */
  private _changeTheme$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Current theme
   *
   * @private
   */
  private _currentTheme: THEME;

  /**
   * Access to _changeTheme$
   */
  public changeTheme$: Observable<any> = this._changeTheme$.asObservable();

  constructor(
    private _cookie: CookieService,
    private _env: EnvironmentService,
    private _platform: PlatformService,
    private _aeService: ArabicService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._resolveDefaultTheme$().pipe(
      tap((theme: THEME) => this.setTheme(theme))
    ).subscribe();
  }

  /**
   * Access to current theme value
   */
  get currentTheme(): THEME {
    return this._currentTheme;
  }

  /**
   * Access to available themes
   */
  get themes() {
    return THEME;
  }

  /**
   * Use provided theme
   * @param theme
   * @param background
   */
  public setTheme(theme: THEME, background?: any) {
    if (theme !== this.currentTheme) {
      this._changeTheme$.next(theme);
      this._currentTheme = theme;
    }
  }

  /**
   * Resolve user default theme
   */
  private _resolveDefaultTheme$(): Observable<THEME> {
    return this._env.env$.pipe(
      map(() => {
        let userTheme;
        if (this._cookie.check(THEME_COOKIE)) {
          const parsedThemeCookie = parseInt(this._cookie.get(THEME_COOKIE), 10);
          if (!isNaN(parsedThemeCookie) && Object.values(THEME).includes(parsedThemeCookie)) {
            userTheme = parsedThemeCookie;
          } else {
            userTheme = THEME.DEFAULT;
          }
        } else {
          userTheme = THEME.DEFAULT;
          switch (true) {
            case this._aeService.arLocales.includes(this._env.env.country.short):
              userTheme = THEME.AR;
              break;
          }
        }
        return userTheme;
      })
    );
  }

  /**
   * Current theme resolver
   */
  public resolveTheme$() {
    if (this._platform.isBrowser) {
      this.changeTheme$.pipe(
        tap((theme) => {
          this._document.documentElement.classList.add('theme-transition');
          switch (Number(theme)) {
            case this.themes.DEFAULT:
              this._addGlobalClass(GLOBAL_CLASSES.DEFAULT);
              break;
            case this.themes.AR:
              this._addGlobalClass(GLOBAL_CLASSES.AR);
              break;
          }
          setTimeout(() => {
            this._document.documentElement.classList.remove('theme-transition');
          }, 1500);
          if (this._platform.isBrowser) {
            this._cookie.set(THEME_COOKIE, theme.toString(), 999, '/', (window as any).location.hostname);
          }
        })
      ).subscribe();
    }
  }

  /**
   * Add global class and remove previous classes
   * @param globalClass
   * @private
   */
  private _addGlobalClass(globalClass: string) {
    this._document.documentElement.classList.add(globalClass);
    this._filterGlobalClasses(globalClass);
  }

  /**
   * Remove and filter previous classes
   * @param key
   * @private
   */
  private _filterGlobalClasses(key: string) {
    Object.values(GLOBAL_CLASSES).forEach(item => {
      if (key !== item) {
        this._document.documentElement.classList.remove(item);
      }
    });
  }
}
